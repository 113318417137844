// data.js
export const nflTeamsRecord = [
    {team: 'Arizona Cardinals', record: '1-5-0'},
    {team: 'Atlanta Falcons', record: '1-5-0'},
    {team: 'Baltimore Ravens', record: '1-5-0'},
    {team: 'Buffalo Bills', record: '1-5-0'},
    {team: 'Carolina Panthers', record: '1-5-0'},
    {team: 'Chicago Bears', record: '1-5-0'},
    {team: 'Cincinnati Bengals', record: '1-5-0'},
    {team: 'Cleveland Browns', record: '1-5-0'},
    {team: 'Dallas Cowboys', record: '1-5-0'},
    {team: 'Denver Broncos', record: '1-5-0'},
    {team: 'Detroit Lions', record: '1-5-0'},
    {team: 'Green Bay Packers', record: '1-5-0'},
    {team: 'Houston Texans', record: '1-5-0'},
    {team: 'Indianapolis Colts', record: '1-5-0'},
    {team: 'Jacksonville Jaguars', record: '1-5-0'},
    {team: 'Kansas City Chiefs', record: '1-5-0'},
    {team: 'Las Vegas Raiders', record: '1-5-0'},
    {team: 'Los Angeles Chargers', record: '1-5-0'},
    {team: 'Los Angeles Rams', record: '1-5-0'},
    {team: 'Miami Dolphins', record: '1-5-0'},
    {team: 'Minnesota Vikings', record: '1-5-0'},
    {team: 'New England Patriots', record: '1-5-0'},
    {team: 'New Orleans Saints', record: '1-5-0'},
    {team: 'New York Giants', record: '1-5-0'},
    {team: 'New York Jets', record: '1-5-0'},
    {team: 'Philadelphia Eagles', record: '1-5-0'},
    {team: 'Pittsburgh Steelers', record: '1-5-0'},
    {team: 'San Francisco 49ers', record: '1-5-0'},
    {team: 'Seattle Seahawks', record: '1-5-0'},
    {team: 'Tampa Bay Buccaneers', record: '1-5-0'},
    {team: 'Tennessee Titans', record: '1-5-0'},
    {team: 'Washington Football Team', record: '1-5-0'}
    ];

  
export const weeksData = [
    {
      week: 7,
      games: [
        {
          homeTeam: 'New Orleans Saints',
          awayTeam: 'Jacksonville Jaguars',
          homeRecord: '3-4',
          awayRecord: '5-2',
          stadium: 'Stadium A',
          projectedWinner: 'Jacksonville Jaguars'
        },
        {
            homeTeam: 'Chicago Bears',
            awayTeam: 'Las Vegas Raiders',
            homeRecord: '1-5',
            awayRecord: '3-3',
            stadium: 'Soldier Field',
            projectedWinner: 'Las Vegas Raiders'
          }
      ]
    },
    {
        week: 8,
        games: [
          {
            homeTeam: 'Buffalo Bills',
            awayTeam: 'Tampa Bay Buccaneers',
            homeRecord: '4-2',
            awayRecord: '3-2',
            stadium: 'Stadium A',
            projectedWinner: 'Team A'
          },
          {
            homeTeam: 'Team A',
            awayTeam: 'Team B',
            homeRecord: '1-0',
            awayRecord: '0-1',
            stadium: 'Stadium A',
            projectedWinner: 'Team A'
          }
        ]
      },
  ];
  