// WeekTable.js
import React from 'react';

function WeekTable({ games }) {
    return (
      <table>
        <thead>
          <tr>
            <th>Home Team</th>
            <th>Away Team</th>
            <th>Home Record</th>
            <th>Away Record</th>
            <th>Stadium</th>
            <th>Projected Winner</th>
          </tr>
        </thead>
        <tbody>
          {games.map((game, index) => (
            <tr key={index}>
              <td>{game.homeTeam}</td>
              <td>{game.awayTeam}</td>
              <td>{game.homeRecord}</td>
              <td>{game.awayRecord}</td>
              <td>{game.stadium}</td>
              <td>{game.projectedWinner}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
  
export default WeekTable;
