import React, { useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import WeekTable from './WeekTable';
import { weeksData } from './data';

function App() {
  const [tables] = useState({
    bestTopLoader: [
      { name: '200ct 3x4 35 pt Regular Ultra Pro Top Loader w/Sleeves', link: 'https://amzn.to/46GEx5e' },
      { name: '100ct 3x4 35 pt Regular Ultra Pro Top Loader w/Sleeves', link: 'https://amzn.to/4903pXf' },
      { name: '1000ct 3x4 35 pt Regular Cardboard Gold Top Loader w/o Sleeves', link: 'https://www.cardboardgold.com/toploader-3x4-for-standard-size-cards-25-c3425.html' },
      { name: '500ct 3x4 140 pt Thick Cardboard Gold Top Loader w/o Sleeves', link: 'https://www.cardboardgold.com/to3x43140ptc.html' }
    ],
    bestDealWebsite: [
      { name: 'Slickdeals', link: 'https://slickdeals.net/' },
      { name: 'Bensbargains', link: 'https://bensbargains.com/' },
      { name: 'Brads Deals', link: 'https://bradsdeals.com/' },
      { name: 'Groupon', link: 'https://groupon.com/' },
      { name: 'LivingSocial', link: 'https://livingsocial.com/' },
    ],
    mostPopularDeals: [
      { name: 'Stanley Quencher H2.0 FlowState Stainless Steel Vacuum Insulated Tumbler with Lid and Straw', link: 'https://amzn.to/3S9SpAS' },
      { name: 'Bedsure Satin Pillowcase for Hair and Skin Queen - Silver Grey Silky Pillowcase 20x30 Inches', link: 'https://amzn.to/3S7LcRw' },
      { name: 'Queen Size 4 Piece Bedding Sheet Set - Breathable & Cooling Bed Sheets', link: 'https://amzn.to/3tEczsl' },
      { name: 'Bedsure Fleece Bed Blankets Queen Size Grey - Soft Lightweight Plush Fuzzy Cozy Luxury Blanket Microfiber, 90x90 inches', link: <iframe sandbox="allow-popups allow-scripts allow-modals allow-forms allow-same-origin" style="width:120px;height:240px;" marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=fitz15670d-20&language=en_US&marketplace=amazon&region=US&placement=B0157T2ENY&asins=B0157T2ENY&linkId=6d7e4cbddf0dcc30802ad2a371b0b8ae&show_border=true&link_opens_in_new_window=true"></iframe> }
    ],
    bestFoodLongIsland: [
      { name: 'Food Place A', link: 'https://foodplaceA.com' },
      // ... more food places
    ],
  }
  );

  return (
    <Router>
      <div className="App">
      <h1 class="fancy-title">Fitz Top Picks</h1>

        <nav>
        <Link to="/" className="bubbly-link">Home</Link>
        <Link to="/fantasy-football" className="bubbly-link">Fantasy Football</Link>
        </nav>

        <Routes>
          <Route path="/" element={
            <>
              <Table title="Best Deal Websites" data={tables.bestDealWebsite} />
              <Table title="Most Popular Deals" data={tables.mostPopularDeals} />
              <Table title="Best Top Loaders" data={tables.bestTopLoader} />
              <Table title="Best Food Long Island NY" data={tables.bestFoodLongIsland} />
            </>
          } />
          <Route path="/fantasy-football/*" element={<FantasyFootball />} />
          <Route path="/privacy-center" element={<PrivacyCenter />} />
        </Routes>
        
        <nav>
        <Link to="/" className="bubbly-link">Home</Link>
        <Link to="/privacy-center" className="bubbly-link">Privacy Center</Link>
        </nav>

      </div>
    </Router>
  );
}

function PrivacyCenter() {
  return (
    <div>
      <h1>Privacy Policy</h1>
      
      <h2>Introduction</h2>
      <p>Welcome to [Organization Name]'s Privacy Center. We are committed to protecting the privacy and security of your personal information. This policy outlines our practices regarding data collection, use, and sharing, and how we protect your privacy.</p>
      
      <h2>Information Collection</h2>
      <p>We collect information that identifies, relates to, describes, or could reasonably be linked with an individual or device. This may include:</p>
      <ul>
        <li>Personal identifiers like your name, email address, and phone number.</li>
        <li>Internet activity, including your browsing and search history on our website.</li>
        <li>Geolocation data.</li>
      </ul>

      <h2>Use of Information</h2>
      <p>The information we collect is used for the following purposes:</p>
      <ul>
        <li>To provide, maintain, and improve our services.</li>
        <li>To personalize your experience with our offerings.</li>
        <li>For marketing and promotional activities, with your consent where required.</li>
        <li>To comply with legal obligations.</li>
      </ul>

      <h2>Data Sharing and Disclosure</h2>
      <p>We may share your information with third parties in the following situations:</p>
      <ul>
        <li>With service providers who assist us in operating our business.</li>
        <li>In response to a request for information if we believe disclosure is in accordance with, or required by, any applicable law or legal process.</li>
        <li>With your consent or at your direction.</li>
      </ul>

      <h2>Data Security</h2>
      <p>We implement robust security measures to protect your personal information from unauthorized access, use, or disclosure.</p>

      <h2>User Rights</h2>
      <p>You have the right to access, correct, or delete your personal data. You may also have the right to object to or restrict certain processing of your data.</p>

      <h2>Cookies and Tracking Technologies</h2>
      <p>Our website uses cookies and similar tracking technologies to improve user experience and analyze website usage.</p>

      <h2>International Data Transfers</h2>
      <p>Personal information we collect may be transferred to, and processed in, countries outside of your country of residence. These countries may have data protection laws that are different from the laws of your country.</p>

      <h2>Changes to the Privacy Policy</h2>
      <p>We may update this policy from time to time. The latest version will always be posted on our website.</p>

      <h2>Contact Information</h2>
      <p>For any questions or concerns regarding your privacy, please contact us at [Contact Information].</p>
    </div>
  );
}

function FantasyFootball() {
  return (
    <div>
      {weeksData.map((weekData, index) => (
        <div key={index}>
          <Link to={`${weekData.week}`}>Week {weekData.week}</Link>
        </div>
      ))}
      <Routes>
        {weeksData.map((weekData, index) => (
          <Route key={index} path={`${weekData.week}`} element={<WeekTable games={weekData.games} />} />
        ))}
      </Routes>
    </div>
  );
}

const Table = ({ title, data }) => {
  return (
    <div className="table-container">
      <h2>{title}</h2>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Link</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td>{item.name}</td>
              <td><a href={item.link} target="_blank" rel="noopener noreferrer">Visit</a></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default App;